<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>Import wpłat</h2>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">
          <strong>Import wpłat</strong>
        </li>
      </ol>
    </div>
  </div>

  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Filtry</h5>
          </div>
          <div class="ibox-content">
            <div class="row">
              <div class="col-md-3">
                <label for="file" class="control-label col-sm-2">
                  Plik wyciągu
                </label>
                <input
                  @change="changeFile"
                  type="file"
                  id="file"
                  name="file"
                  accept=".csv, text/csv"
                  required="required"
                />
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label class="control-label col-sm-2">Bank</label>
                  <div class="col-sm-10">
                    <Multiselect
                      v-model="bank"
                      mode="single"
                      placeholder="Bank"
                      track-by="name"
                      label="name"
                      :close-on-select="true"
                      :search="true"
                      :options="banks"
                    >
                    </Multiselect>
                  </div>
                </div>
              </div>
              <div class="col-md-2">
                <button
                  @click="importFile"
                  class="btn btn-primary"
                  :disabled="!file || !bank"
                >
                  Importuj
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Wpłaty nieprzypisane do zamówień</h5>
          </div>
          <div class="ibox-content">
            <EasyDataTable
              v-model:server-options="serverOptions"
              :server-items-length="itemsAll"
              :headers="headers"
              :items="payments.unassigned"
              :loading="loading"
              alternating
              multi-sort
              show-index
              border-cell
              class="messages table-search"
            >
            </EasyDataTable>
          </div>
        </div>
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Wpłaty przypisane do zamówień</h5>
          </div>
          <div class="ibox-content">
            <EasyDataTable
              v-model:server-options="serverOptions"
              :server-items-length="itemsAll"
              :headers="headers"
              :items="payments.assigned"
              :loading="loading"
              alternating
              multi-sort
              show-index
              border-cell
              class="messages table-search"
            >
            </EasyDataTable>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, watch, ref } from "vue";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import { useStore } from "vuex";

export default {
  name: "PaymentImport",
  mixins: [],
  components: {
    Multiselect,
  },
  props: {},

  setup() {
    const store = useStore();
    const file = ref();
    const bank = ref();
    const errorsAdminData = ref();
    const showErrors = ref(false);
    const showModalErrors = ref(false);
    const paymentsData = computed(() => store.getters.getPaymentsImportData);
    const banks = ref([
      { value: "santander", name: "Santander Bank" },
      { value: "mbank", name: "mBank" },
    ]);
    const payments = ref({
      assigned: [],
      unassigned: [],
    });
    let headers = [
      { text: "Tytuł przelewu", value: "title" },
      { text: "Kwota", value: "amount" },
    ];

    const changeFile = (f) => {
      file.value = f.srcElement?.files[0];
    };
    const importFile = () => {
      store.dispatch("paymentsImport", {
        file: file.value,
        bank: bank.value,
      });
    };

    watch(
      () => paymentsData.value,
      function (newValue) {
        payments.value = {
          assigned: [],
          unassigned: [],
        };
        newValue.data.assigned?.forEach((element) => {
          payments.value.assigned.push({
            title: element[0],
            amount: element[1],
          });
        });
        newValue.data.unassigned?.forEach((element) => {
          payments.value.unassigned.push({
            title: element[0],
            amount: element[1],
          });
        });
      }
    );

    return {
      errorsAdminData,
      showErrors,
      showModalErrors,
      file,
      changeFile,
      importFile,
      banks,
      bank,
      payments,
      headers,
    };
  },
  computed: {},
};
</script>
