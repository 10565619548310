<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>Maszyny</h2>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">
          <router-link to="/machines">Maszyny</router-link>
        </li>
        <li class="active">
          <strong>Nowa</strong>
        </li>
      </ol>
    </div>
  </div>

  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Opis maszyny</h5>
          </div>

          <div class="form-horizontal">
            <div class="ibox-content">
              <div class="form-group">
                <label class="control-label col-sm-2">Nazwa</label>
                <div class="col-sm-10">
                  <input
                    v-model="machineObj.name"
                    name="name"
                    type="text"
                    class="form-control inputClass"
                    placeholder="Nazwa"
                    required="required"
                  />
                  <div v-if="errorsAdminData?.data?.name" class="has-error">
                    <span class="help-block">
                      {{ errorsAdminData.data.name[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">Model</label>
                <div class="col-sm-10">
                  <input
                    v-model="machineObj.model"
                    name="name"
                    type="text"
                    class="form-control inputClass"
                    placeholder="Model"
                    required="required"
                  />
                  <div v-if="errorsAdminData?.data?.model" class="has-error">
                    <span class="help-block">
                      {{ errorsAdminData.data.name[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">Typ</label>
                <div class="col-sm-10">
                  <input
                    v-model="machineObj.type"
                    name="name"
                    type="text"
                    class="form-control inputClass"
                    placeholder="Typ"
                    required="required"
                  />
                  <div v-if="errorsAdminData?.data?.type" class="has-error">
                    <span class="help-block">
                      {{ errorsAdminData.data.name[0] }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Czynności</h5>
          </div>
          <div class="form-horizontal">
            <div class="ibox-content">
              <button @click="mActions = true" class="btn btn-sm btn-primary">
                Dodaj
              </button>
              <EasyDataTable
                :headers="headers"
                :items="machineObj.actions"
                :loading="loading"
                alternating
              >
                <template #item-actions="item">
                  <button
                    @click="editAction(item)"
                    class="btn btn-warning btn-xs"
                  >
                    <i class="fa fa-pencil" aria-hidden="true"></i>
                  </button>
                  <button
                    @click="removeAction(item)"
                    class="btn btn-danger btn-xs"
                  >
                    <i class="fa fa-trash"></i>
                  </button>
                </template>
                <template #item-is_work_preview="item">
                  <i v-if="item.is_work_preview" class="fa fa-check"></i>
                  <i v-else class="fa fa-times"></i>
                </template>
                <template #item-is_mounting_info="item">
                  <i v-if="item.is_mounting_info" class="fa fa-check"></i>
                  <i v-else class="fa fa-times"></i>
                </template>
                <template #item-is_use_codes_verification="item">
                  <i v-if="item.is_use_codes_verification" class="fa fa-check">
                  </i>
                  <i v-else class="fa fa-times"></i>
                </template>
                <template #item-is_use_codes_verification_print="item">
                  <i
                    v-if="item.is_use_codes_verification_print"
                    class="fa fa-check"
                  >
                  </i>
                  <i v-else class="fa fa-times"></i>
                </template>
                <template #item-is_assembly_codes_verification="item">
                  <i
                    v-if="item.is_assembly_codes_verification"
                    class="fa fa-check"
                  >
                  </i>
                  <i v-else class="fa fa-times"></i>
                </template>
              </EasyDataTable>
            </div>
          </div>
        </div>
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Konfiguracja maszyny</h5>
          </div>

          <div class="form-horizontal">
            <div class="ibox-content">
              <div class="form-group">
                <label class="control-label col-sm-2">Zdjęcie</label>
                <div class="col-sm-2">
                  <picture-input
                    ref="pictureInput"
                    width="300"
                    height="300"
                    margin="16"
                    accept="image/jpeg,image/png"
                    size="10"
                    button-class="btn"
                    :custom-strings="{
                      upload: '<h1>Uploading!</h1>',
                      drag: 'Drag & Drop',
                    }"
                    @change="changePhoto"
                  >
                  </picture-input>
                </div>
              </div>
              <div class="form-group">
                <div class="col-sm-4 col-sm-offset-2">
                  <router-link class="btn btn-white" to="/machines">
                    Anuluj
                  </router-link>
                  <button @click="create" class="btn btn-primary">Dodaj</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="mActions" class="modal-dialog abs">
    <div class="modal-content">
      <div class="inmodal">
        <div class="modal-header">
          <h4 class="modal-title">Dodaj czynność maszyny</h4>
        </div>
        <div class="modal-body">
          <div class="form-horizontal">
            <div class="ibox-content">
              <div class="form-group">
                <label class="control-label col-sm-2">Nazwa</label>
                <div class="col-sm-10">
                  <input
                    v-model="actions.name"
                    name="name"
                    type="text"
                    class="form-control inputClass"
                    placeholder="Nazwa"
                    required="required"
                  />
                  <div
                    v-if="!actions.name && showModalErrors"
                    class="has-error"
                  >
                    <span class="help-block">To pole musi być wypełnione!</span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="col-sm-offset-2 col-sm-10">
                  <div class="checkbox checkbox-primary">
                    <label>
                      <input
                        v-model="actions.is_work_preview"
                        name="is_work_preview"
                        type="checkbox"
                      />
                      <span>Podgląd pracy</span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="col-sm-offset-2 col-sm-10">
                  <div class="checkbox checkbox-primary">
                    <label>
                      <input
                        v-model="actions.is_mounting_info"
                        name="is_mounting_info"
                        type="checkbox"
                      />
                      <span>Informacje o montażu</span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="col-sm-offset-2 col-sm-10">
                  <div class="checkbox checkbox-primary">
                    <label>
                      <input
                        v-model="actions.is_use_codes_verification"
                        name="is_use_codes_verification"
                        type="checkbox"
                      />
                      <span>Weryfikacja kodów użytków</span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="col-sm-offset-2 col-sm-10">
                  <div class="checkbox checkbox-primary">
                    <label>
                      <input
                        v-model="actions.is_use_codes_verification_print"
                        name="is_use_codes_verification_print"
                        type="checkbox"
                      />
                      <span>Drukowanie i weryfikacja kodów użytków</span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="col-sm-offset-2 col-sm-10">
                  <div class="checkbox checkbox-primary">
                    <label>
                      <input
                        v-model="actions.is_assembly_codes_verification"
                        name="is_use_codes_verification_print"
                        type="checkbox"
                      />
                      <span>Weryfikacja kodu montażu</span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">Opis</label>
                <div class="col-sm-10 QuillEditor-box">
                  <QuillEditor
                    theme="snow"
                    contentType="html"
                    v-model:content="actions.description"
                  />
                  <div
                    v-if="errorsAdminData?.data?.description"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.description.name[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">
                  Checklista przed rozpoczęciem
                </label>
                <div class="col-sm-10">
                  <input
                    v-model="checklist_before_start"
                    name="checklist_before_start"
                    type="text"
                    class="form-control inputClass"
                    placeholder="Checklista przed rozpoczęciem"
                    v-on:keyup.enter="addToChecklist(1)"
                  />
                  <ul class="list-group">
                    <li
                      v-for="(item, key) in actions.checklist_before_start"
                      class="list-group-item"
                      :key="key"
                    >
                      <span data-ng-bind="item">{{ item }}</span>
                      <a
                        @click="removeFromChecklist(1, key)"
                        class="text-danger"
                      >
                        <i class="fa fa-times" aria-hidden="true"></i>
                        <span>usuń</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">
                  Checklista przed zakończeniem
                </label>
                <div class="col-sm-10">
                  <input
                    v-model="checklist_before_end"
                    name="checklist_before_end"
                    type="text"
                    class="form-control inputClass"
                    placeholder="Checklista przed zakończeniem"
                    required="required"
                    v-on:keyup.enter="addToChecklist(0)"
                  />
                  <ul class="list-group">
                    <li
                      v-for="(item, key) in actions.checklist_before_end"
                      class="list-group-item"
                      :key="key"
                    >
                      <span data-ng-bind="item">{{ item }}</span>
                      <a
                        @click="removeFromChecklist(0, key)"
                        class="text-danger"
                      >
                        <i class="fa fa-times" aria-hidden="true"></i>
                        <span>usuń</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button @click="mActions = false" class="btn btn-white">
            Anuluj
          </button>
          <button v-if="isEdit" @click="saveAction()" class="btn btn-primary">
            Zapisz
          </button>
          <button v-else @click="addAction()" class="btn btn-primary">
            Dodaj
          </button>
        </div>
      </div>
    </div>
  </div>
  <div v-if="mActions" @click="mActions = false" class="modal-backdrop"></div>
</template>

<script>
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import router from "../../router/router";
import PictureInput from "vue-picture-input";

export default {
  name: "MachinesCreate",
  mixins: [],
  components: {
    PictureInput,
  },
  props: {},

  setup() {
    const actions = ref({
      is_mounting_info: false,
      is_work_preview: false,
      is_use_codes_verification_print: false,
      is_use_codes_verification: false,
      is_assembly_codes_verification: false,
      checklist_before_start: [],
      checklist_before_end: [],
    });
    const checklist_before_end = ref();
    const checklist_before_start = ref();
    const machineObj = ref({
      name: "",
      model: "",
      type: "",
      image: "",
      actions: [],
    });

    const errorsAdminData = ref();
    const mActions = ref(false);
    const isEdit = ref(false);
    const editIndex = ref();
    const showErrors = ref(false);
    const showModalErrors = ref(false);
    const store = useStore();
    const createMachinesData = computed(
      () => store.getters.getCreateMachinesData
    );
    let headers = [
      { text: "Nazwa", value: "name", sortable: true },
      { text: "Podgląd pracy", value: "is_work_preview", sortable: true },
      {
        text: "Informacje o montażu",
        value: "is_mounting_info",
        sortable: true,
      },
      {
        text: "Weryfikacja kodów użytkowników",
        value: "is_use_codes_verification",
        sortable: true,
      },
      {
        text: "Drukowanie i weryfikacja kodów użytkowników",
        value: "is_use_codes_verification_print",
        sortable: true,
      },
      {
        text: "Weryfikacja kodu montażu",
        value: "is_assembly_codes_verification",
        sortable: true,
      },
      { text: "Akcje", value: "actions" },
    ];

    const create = () => {
      showErrors.value = true;
      store.dispatch("createMachines", machineObj.value);
    };

    const addAction = () => {
      if (actions.value.name) {
        machineObj.value.actions.push(actions.value);
        mActions.value = false;
        resetAction();
      } else {
        showModalErrors.value = true;
      }
    };

    const saveAction = () => {
      if (actions.value.name) {
        machineObj.value.actions[editIndex.value] = actions.value;
        mActions.value = false;
        isEdit.value = false;
        resetAction();
      } else {
        showModalErrors.value = true;
      }
    };

    const resetAction = () => {
      actions.value = ref({
        is_mounting_info: false,
        is_work_preview: false,
        is_use_codes_verification_print: false,
        is_use_codes_verification: false,
        is_assembly_codes_verification: false,
        checklist_before_start: [],
        checklist_before_end: [],
      });
    };

    const removeAction = (item) => {
      var index = machineObj.value.actions
        .map(function (e) {
          return e.name;
        })
        .indexOf(item.name);
      machineObj.value.actions.splice(index, 1);
    };

    const editAction = (item) => {
      mActions.value = true;
      isEdit.value = true;
      actions.value = item;
      editIndex.value = machineObj.value.actions
        .map(function (e) {
          return e.name;
        })
        .indexOf(item.name);
    };

    const addToChecklist = (type) => {
      if (type === 1) {
        console.log("xxx", actions.value);
        actions.value.checklist_before_start.push(checklist_before_start.value);
        checklist_before_start.value = "";
      } else {
        actions.value.checklist_before_end.push(checklist_before_end.value);
        checklist_before_end.value = "";
      }
    };

    const removeFromChecklist = (type, key) => {
      if (type === 1) {
        actions.value.checklist_before_start.splice(key, 1);
      } else {
        actions.value.checklist_before_end.splice(key, 1);
      }
    };

    const changePhoto = (image) => {
      if (image.srcElement?.files[0]) {
        machineObj.value.image = image.srcElement?.files[0];
      }
    };

    watch(
      () => createMachinesData.value,
      function (newValue) {
        if (newValue.status === 201) {
          router.push({ name: "MachinesList", path: "/machines" }).then(() => {
            store.dispatch("showToaster", {
              type: "success",
              content: "Nowa maszyna została dodana",
            });
          });
        } else {
          var msg = [];
          for (const [key, value] of Object.entries(newValue.data)) {
            msg.push(key + ": " + value);
          }

          store.dispatch("showToaster", {
            type: "error",
            content: msg[0],
          });
          errorsAdminData.value = newValue;
        }
      }
    );

    return {
      machineObj,
      errorsAdminData,
      showErrors,
      create,
      mActions,
      actions,
      checklist_before_end,
      checklist_before_start,
      addToChecklist,
      removeFromChecklist,
      addAction,
      headers,
      showModalErrors,
      removeAction,
      editAction,
      saveAction,
      isEdit,
      changePhoto,
    };
  },
  computed: {},
};
</script>
