<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>Produkty</h2>
      <ol class="breadcrumb">
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li class="active">
          <router-link to="/products">Produkty</router-link>
        </li>
        <li class="active">
          <strong>Nowy</strong>
        </li>
      </ol>
    </div>
  </div>

  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Opis produktu [pl]</h5>
          </div>

          <div class="form-horizontal">
            <div class="ibox-content">
              <div class="form-group">
                <label class="control-label col-sm-2">Nazwa</label>
                <div class="col-sm-10">
                  <input
                    v-model="productObj.name_pl"
                    type="text"
                    class="form-control inputClass"
                    placeholder="Nazwa"
                    required="required"
                  />
                  <div v-if="errorsAdminData?.data?.name_pl" class="has-error">
                    <span class="help-block">
                      {{ errorsAdminData.data.name_pl[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">[SEO] Tytuł</label>
                <div class="col-sm-10">
                  <input
                    v-model="productObj.title_seo_pl"
                    type="text"
                    class="form-control inputClass"
                    placeholder="[SEO] Tytuł"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.title_seo_pl"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.title_seo_pl[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">
                  [SEO] Słowa kluczowe
                </label>
                <div class="col-sm-10">
                  <input
                    v-model="productObj.keywords_seo_pl"
                    type="text"
                    class="form-control inputClass"
                    placeholder="[SEO] Słowa kluczowe"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.keywords_seo_pl"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.keywords_seo_pl[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">[SEO] Opis</label>
                <div class="col-sm-10">
                  <input
                    v-model="productObj.description_seo_pl"
                    type="text"
                    class="form-control inputClass"
                    placeholder="[SEO] Opis"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.description_seo_pl"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.description_seo_pl[0] }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="control-label col-sm-2">Krótka treść</label>
                <div class="col-sm-10 QuillEditor-box">
                  <QuillEditor
                    theme="snow"
                    contentType="html"
                    v-model:content="productObj.short_description_pl"
                  />
                  <div
                    v-if="errorsAdminData?.data?.short_description_pl"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.short_description_pl[0] }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="control-label col-sm-2">Treść</label>
                <div class="col-sm-10 QuillEditor-box">
                  <QuillEditor
                    theme="snow"
                    contentType="html"
                    v-model:content="productObj.description_pl"
                  />
                  <div
                    v-if="errorsAdminData?.data?.description_pl"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.description_pl[0] }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Opis produktu [en]</h5>
          </div>

          <div class="form-horizontal">
            <div class="ibox-content">
              <div class="form-group">
                <label class="control-label col-sm-2">Nazwa</label>
                <div class="col-sm-10">
                  <input
                    v-model="productObj.name_en"
                    type="text"
                    class="form-control inputClass"
                    placeholder="Nazwa"
                    required="required"
                  />
                  <div v-if="errorsAdminData?.data?.name_en" class="has-error">
                    <span class="help-block">
                      {{ errorsAdminData.data.name_en[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">[SEO] Tytuł</label>
                <div class="col-sm-10">
                  <input
                    v-model="productObj.title_seo_en"
                    type="text"
                    class="form-control inputClass"
                    placeholder="[SEO] Tytuł"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.title_seo_en"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.title_seo_en[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">
                  [SEO] Słowa kluczowe
                </label>
                <div class="col-sm-10">
                  <input
                    v-model="productObj.keywords_seo_en"
                    type="text"
                    class="form-control inputClass"
                    placeholder="[SEO] Słowa kluczowe"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.keywords_seo_en"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.keywords_seo_en[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">[SEO] Opis</label>
                <div class="col-sm-10">
                  <input
                    v-model="productObj.description_seo_en"
                    type="text"
                    class="form-control inputClass"
                    placeholder="[SEO] Opis"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.description_seo_en"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.description_seo_en[0] }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="control-label col-sm-2">Krótka treść</label>
                <div class="col-sm-10 QuillEditor-box">
                  <QuillEditor
                    theme="snow"
                    contentType="html"
                    v-model:content="productObj.short_description_en"
                  />
                  <div
                    v-if="errorsAdminData?.data?.short_description_en"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.short_description_en[0] }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="control-label col-sm-2">Treść</label>
                <div class="col-sm-10 QuillEditor-box">
                  <QuillEditor
                    theme="snow"
                    contentType="html"
                    v-model:content="productObj.description_en"
                  />
                  <div
                    v-if="errorsAdminData?.data?.description_en"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.description_en[0] }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Opis produktu [de]</h5>
          </div>

          <div class="form-horizontal">
            <div class="ibox-content">
              <div class="form-group">
                <label class="control-label col-sm-2">Nazwa</label>
                <div class="col-sm-10">
                  <input
                    v-model="productObj.name_de"
                    type="text"
                    class="form-control inputClass"
                    placeholder="Nazwa"
                    required="required"
                  />
                  <div v-if="errorsAdminData?.data?.name_de" class="has-error">
                    <span class="help-block">
                      {{ errorsAdminData.data.name_de[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">[SEO] Tytuł</label>
                <div class="col-sm-10">
                  <input
                    v-model="productObj.title_seo_de"
                    type="text"
                    class="form-control inputClass"
                    placeholder="[SEO] Tytuł"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.title_seo_de"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.title_seo_de[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">
                  [SEO] Słowa kluczowe
                </label>
                <div class="col-sm-10">
                  <input
                    v-model="productObj.keywords_seo_de"
                    type="text"
                    class="form-control inputClass"
                    placeholder="[SEO] Słowa kluczowe"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.keywords_seo_de"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.keywords_seo_de[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">[SEO] Opis</label>
                <div class="col-sm-10">
                  <input
                    v-model="productObj.description_seo_de"
                    type="text"
                    class="form-control inputClass"
                    placeholder="[SEO] Opis"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.description_seo_de"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.description_seo_de[0] }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="control-label col-sm-2">Krótka treść</label>
                <div class="col-sm-10 QuillEditor-box">
                  <QuillEditor
                    theme="snow"
                    contentType="html"
                    v-model:content="productObj.short_description_de"
                  />
                  <div
                    v-if="errorsAdminData?.data?.short_description_de"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.short_description_de[0] }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="control-label col-sm-2">Treść</label>
                <div class="col-sm-10 QuillEditor-box">
                  <QuillEditor
                    theme="snow"
                    contentType="html"
                    v-model:content="productObj.description_de"
                  />
                  <div
                    v-if="errorsAdminData?.data?.description_de"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.description_de[0] }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Opis produktu [de_de]</h5>
          </div>

          <div class="form-horizontal">
            <div class="ibox-content">
              <div class="form-group">
                <label class="control-label col-sm-2">Nazwa</label>
                <div class="col-sm-10">
                  <input
                    v-model="productObj.name_cpde"
                    type="text"
                    class="form-control inputClass"
                    placeholder="Nazwa"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.name_cpde"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.name_cpde[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">[SEO] Tytuł</label>
                <div class="col-sm-10">
                  <input
                    v-model="productObj.title_seo_cpde"
                    type="text"
                    class="form-control inputClass"
                    placeholder="[SEO] Tytuł"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.title_seo_cpde"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.title_seo_cpde[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">
                  [SEO] Słowa kluczowe
                </label>
                <div class="col-sm-10">
                  <input
                    v-model="productObj.keywords_seo_cpde"
                    type="text"
                    class="form-control inputClass"
                    placeholder="[SEO] Słowa kluczowe"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.keywords_seo_cpde"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.keywords_seo_cpde[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">[SEO] Opis</label>
                <div class="col-sm-10">
                  <input
                    v-model="productObj.description_seo_cpde"
                    type="text"
                    class="form-control inputClass"
                    placeholder="[SEO] Opis"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.description_seo_cpde"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.description_seo_cpde[0] }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="control-label col-sm-2">Krótka treść</label>
                <div class="col-sm-10 QuillEditor-box">
                  <QuillEditor
                    theme="snow"
                    contentType="html"
                    v-model:content="productObj.short_description_cpde"
                  />
                  <div
                    v-if="errorsAdminData?.data?.short_description_cpde"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.short_description_cpde[0] }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="control-label col-sm-2">Treść</label>
                <div class="col-sm-10 QuillEditor-box">
                  <QuillEditor
                    theme="snow"
                    contentType="html"
                    v-model:content="productObj.description_cpde"
                  />
                  <div
                    v-if="errorsAdminData?.data?.description_cpde"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.description_cpde[0] }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Opis produktu [de_en]</h5>
          </div>

          <div class="form-horizontal">
            <div class="ibox-content">
              <div class="form-group">
                <label class="control-label col-sm-2">Nazwa</label>
                <div class="col-sm-10">
                  <input
                    v-model="productObj.name_cpen"
                    type="text"
                    class="form-control inputClass"
                    placeholder="Nazwa"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.name_cpen"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.name_cpen[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">[SEO] Tytuł</label>
                <div class="col-sm-10">
                  <input
                    v-model="productObj.title_seo_cpen"
                    type="text"
                    class="form-control inputClass"
                    placeholder="[SEO] Tytuł"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.title_seo_cpen"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.title_seo_cpen[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">
                  [SEO] Słowa kluczowe
                </label>
                <div class="col-sm-10">
                  <input
                    v-model="productObj.keywords_seo_cpen"
                    type="text"
                    class="form-control inputClass"
                    placeholder="[SEO] Słowa kluczowe"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.keywords_seo_cpen"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.keywords_seo_cpen[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">[SEO] Opis</label>
                <div class="col-sm-10">
                  <input
                    v-model="productObj.description_seo_cpen"
                    type="text"
                    class="form-control inputClass"
                    placeholder="[SEO] Opis"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.description_seo_cpen"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.description_seo_cpen[0] }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="control-label col-sm-2">Krótka treść</label>
                <div class="col-sm-10 QuillEditor-box">
                  <QuillEditor
                    theme="snow"
                    contentType="html"
                    v-model:content="productObj.short_description_cpen"
                  />
                  <div
                    v-if="errorsAdminData?.data?.short_description_cpen"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.short_description_cpen[0] }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="control-label col-sm-2">Treść</label>
                <div class="col-sm-10 QuillEditor-box">
                  <QuillEditor
                    theme="snow"
                    contentType="html"
                    v-model:content="productObj.description_cpen"
                  />
                  <div
                    v-if="errorsAdminData?.data?.description_cpen"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.description_cpen[0] }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Nakłady</h5>
          </div>
          <div class="form-horizontal">
            <div class="ibox-content">
              <button @click="addQuantities" class="btn btn-sm btn-primary">
                Dodaj
              </button>
              <EasyDataTable
                :headers="headers"
                :items="productObj.quantities"
                :loading="loading"
                alternating
              >
                <template #item-quantity="item">
                  <div v-if="item.isNew">
                    <input
                      v-model="quantities.quantity"
                      type="number"
                      class="form-control inputClass"
                      placeholder="Nakład"
                      required="required"
                    />
                    <div
                      v-if="!quantities.quantity && showModalErrors"
                      class="has-error"
                    >
                      <span class="help-block">
                        To pole musi być wypełnione!
                      </span>
                    </div>
                  </div>
                  <div v-else>
                    <span v-if="item.quantity !== ''">
                      {{ item.quantity }}
                    </span>
                    <span v-else>---</span>
                  </div>
                </template>
                <template #item-display_quantity="item">
                  <div v-if="item.isNew">
                    <input
                      v-model="quantities.display_quantity"
                      type="number"
                      class="form-control inputClass"
                      placeholder="Nakład widoczny"
                      required="required"
                    />
                    <div
                      v-if="!quantities.display_quantity && showModalErrors"
                      class="has-error"
                    >
                      <span class="help-block">
                        To pole musi być wypełnione!
                      </span>
                    </div>
                  </div>
                  <div v-else>
                    <span v-if="item.display_quantity !== ''">
                      {{ item.display_quantity }}
                    </span>
                    <span v-else>---</span>
                  </div>
                </template>
                <template #item-price="item">
                  <div v-if="item.isNew">
                    <input
                      v-model="quantities.price"
                      type="number"
                      class="form-control inputClass"
                      placeholder="Cena"
                      required="required"
                    />
                    <div
                      v-if="!quantities.price && showModalErrors"
                      class="has-error"
                    >
                      <span class="help-block">
                        To pole musi być wypełnione!
                      </span>
                    </div>
                  </div>
                  <div v-else>
                    <span v-if="item.price !== ''">
                      {{ item.price }}
                    </span>
                    <span v-else>---</span>
                  </div>
                </template>
                <template #item-realization_days="item">
                  <div v-if="item.isNew">
                    <input
                      v-model="quantities.realization_days"
                      type="number"
                      class="form-control inputClass"
                      placeholder="Dni realizacji"
                      required="required"
                    />
                  </div>
                  <div v-else>
                    <span v-if="item.realization_days !== ''">
                      {{ item.realization_days }}
                    </span>
                    <span v-else>---</span>
                  </div>
                </template>
                <template #item-actions="item">
                  <div v-if="!item.isNew">
                    <button
                      @click="removeQuantity(item)"
                      class="btn btn-danger btn-xs"
                    >
                      <i class="fa fa-trash"></i>
                    </button>
                    <button
                      @click="quantityPrices(item)"
                      class="btn btn-success btn-xs"
                    >
                      <i class="fa fa-cog" aria-hidden="true"></i>
                    </button>
                  </div>
                  <div v-else>
                    <button
                      @click="saveQuantity()"
                      class="btn btn-success btn-xs"
                    >
                      <i class="fa fa-check" aria-hidden="true"></i>
                    </button>
                    <button
                      @click="cancelQuantity(item)"
                      class="btn btn-danger btn-xs"
                    >
                      <i class="fa fa-times" aria-hidden="true"></i>
                    </button>
                  </div>
                </template>
              </EasyDataTable>
            </div>
          </div>
        </div>
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Materiały</h5>
          </div>
          <div class="form-horizontal">
            <div class="ibox-content">
              <div class="form-group">
                <label class="control-label col-sm-2">Wersja polska</label>
                <div class="col-sm-10">
                  <Multiselect
                    v-model="productObj.materials_pl"
                    mode="single"
                    placeholder="Wersja polska"
                    track-by="name"
                    label="name"
                    :close-on-select="true"
                    :search="true"
                    :options="materials"
                  >
                  </Multiselect>
                  <div
                    v-if="errorsAdminData?.data?.materials_pl"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.materials_pl[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">Wersja angielska</label>
                <div class="col-sm-10">
                  <Multiselect
                    v-model="productObj.materials_en"
                    mode="single"
                    placeholder="Wersja angielska"
                    track-by="name"
                    label="name"
                    :close-on-select="true"
                    :search="true"
                    :options="materials"
                  >
                  </Multiselect>
                  <div
                    v-if="errorsAdminData?.data?.materials_en"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.materials_en[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">Wersja niemiecka</label>
                <div class="col-sm-10">
                  <Multiselect
                    v-model="productObj.materials_de"
                    mode="single"
                    placeholder="Wersja niemiecka"
                    track-by="name"
                    label="name"
                    :close-on-select="true"
                    :search="true"
                    :options="materials"
                  >
                  </Multiselect>
                  <div
                    v-if="errorsAdminData?.data?.materials_de"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.materials_de[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">
                  Wersja niemiecka (de)
                </label>
                <div class="col-sm-10">
                  <Multiselect
                    v-model="productObj.materials_cpde"
                    mode="single"
                    placeholder="Wersja niemiecka (de)"
                    track-by="name"
                    label="name"
                    :close-on-select="true"
                    :search="true"
                    :options="materials"
                  >
                  </Multiselect>
                  <div
                    v-if="errorsAdminData?.data?.materials_cpde"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.materials_cpde[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">
                  Wersja angielska (de)
                </label>
                <div class="col-sm-10">
                  <Multiselect
                    v-model="productObj.materials_cpen"
                    mode="single"
                    placeholder="Wersja angielska (de)"
                    track-by="name"
                    label="name"
                    :close-on-select="true"
                    :search="true"
                    :options="materials"
                  >
                  </Multiselect>
                  <div
                    v-if="errorsAdminData?.data?.materials_cpen"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.materials_cpen[0] }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Grupy opcji</h5>
          </div>
          <div class="form-horizontal">
            <div class="ibox-content">
              <button @click="addGroupOptions" class="btn btn-sm btn-primary">
                Dodaj
              </button>
            </div>
            <EasyDataTable
              :headers="headersGroup"
              :items="productObj.group_options"
              :loading="loading"
              :clickRowToExpand="true"
              @click-row="getPOG"
              alternating
            >
              <template #expand="item">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="ibox float-e-margins">
                      <div class="ibox-title">
                        <h5>Opcje</h5>
                        <div class="ibox-content">
                          <button
                            @click="openOptionsModal(item)"
                            class="btn btn-sm btn-primary"
                          >
                            Dodaj
                          </button>
                        </div>
                        <EasyDataTable
                          v-if="item.options"
                          :headers="headersGroup"
                          :items="item.options"
                          :loading="loading"
                          :clickRowToExpand="true"
                          alternating
                        >
                          <template #item-actions="item">
                            <button
                              @click.stop.prevent="removeOption(item)"
                              class="btn btn-danger btn-xs"
                            >
                              <i class="fa fa-trash"></i>
                            </button>
                            <button
                              @click.stop.prevent="groupPrices(item)"
                              class="btn btn-success btn-xs"
                            >
                              <i class="fa fa-cog"></i>
                            </button>
                          </template>
                        </EasyDataTable>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template #item-actions="item">
                <button
                  @click="removeGroup(item)"
                  class="btn btn-danger btn-xs"
                >
                  <i class="fa fa-trash"></i>
                </button>
              </template>
            </EasyDataTable>
          </div>
        </div>
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Sprawdzanie pliku i podgląd</h5>
          </div>
          <div class="form-horizontal">
            <div class="ibox-content">
              <div class="form-group">
                <label class="col-sm-2 control-label">Typ produktu</label>
                <div class="col-sm-10">
                  <select
                    v-model="productObj.kind"
                    class="form-control m-b ng-pristine"
                  >
                    <option
                      v-for="(type, key) in options"
                      :value="type.value"
                      :key="key"
                    >
                      {{ type.name }}
                    </option>
                  </select>
                  <div v-if="errorsAdminData?.data?.kind" class="has-error">
                    <span class="help-block">
                      {{ errorsAdminData.data.kind[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label">Format</label>
                <div class="col-sm-10">
                  <Multiselect
                    v-model="productObj.format"
                    mode="single"
                    placeholder="Format"
                    track-by="name"
                    label="name"
                    :close-on-select="true"
                    :search="true"
                    :options="formats"
                  >
                  </Multiselect>
                  <div v-if="errorsAdminData?.data?.format" class="has-error">
                    <span class="help-block">
                      {{ errorsAdminData.data.format[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">Spad</label>
                <div class="col-sm-10">
                  <input
                    v-model="productObj.bleed"
                    type="number"
                    class="form-control inputClass"
                    placeholder="Spad"
                    required="required"
                  />
                  <div v-if="errorsAdminData?.data?.bleed" class="has-error">
                    <span class="help-block">
                      {{ errorsAdminData.data.bleed[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">Margines tekstu</label>
                <div class="col-sm-10">
                  <input
                    v-model="productObj.text_margin"
                    type="number"
                    class="form-control inputClass"
                    placeholder="Margines tekstu"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.text_margin"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.text_margin[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label">
                  Grupa opcji określająca liczbę stron
                </label>
                <div class="col-sm-10">
                  <Multiselect
                    v-model="productObj.option_group_for_page_count"
                    mode="single"
                    placeholder="Grupa opcji określająca liczbę stron"
                    track-by="name"
                    label="name"
                    :close-on-select="true"
                    :search="true"
                    :options="groupOptions"
                  >
                  </Multiselect>
                  <div
                    v-if="errorsAdminData?.data?.option_group_for_page_count"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.option_group_for_page_count[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label">
                  Grupa opcji określająca liczbę stron okładki
                </label>
                <div class="col-sm-10">
                  <Multiselect
                    v-model="productObj.option_group_for_cover_page_count"
                    mode="single"
                    placeholder="Grupa opcji określająca liczbę stron okładki"
                    track-by="name"
                    label="name"
                    :close-on-select="true"
                    :search="true"
                    :options="groupOptions"
                  >
                  </Multiselect>
                </div>
              </div>
              <div class="form-group">
                <div class="col-sm-offset-2 col-sm-10">
                  <div class="checkbox checkbox-primary">
                    <label>
                      <input
                        v-model="productObj.from_warehouse"
                        type="checkbox"
                      />
                      <span>Produkt z magazynu</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="ibox float-e-margins">
          <div class="ibox-title">
            <h5>Konfiguracja produktu</h5>
          </div>
          <div class="form-horizontal">
            <div class="ibox-content">
              <div class="form-group">
                <label class="col-sm-2 control-label">Grupa pakowania</label>
                <div class="col-sm-10">
                  <Multiselect
                    v-model="productObj.package"
                    mode="single"
                    placeholder="Grupa pakowania"
                    track-by="name"
                    label="name"
                    :close-on-select="true"
                    :search="true"
                    :options="packages"
                  >
                  </Multiselect>
                  <div v-if="errorsAdminData?.data?.package" class="has-error">
                    <span class="help-block">
                      {{ errorsAdminData.data.package[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label">Kategorie</label>
                <div class="col-sm-10">
                  <Multiselect
                    v-model="productObj.categories"
                    mode="tags"
                    placeholder="Kategorie"
                    track-by="name"
                    label="name"
                    :close-on-select="false"
                    :search="true"
                    :options="categories"
                  >
                  </Multiselect>
                  <div
                    v-if="errorsAdminData?.data?.categories"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.categories[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label">Jak przygotować</label>
                <div class="col-sm-10">
                  <Multiselect
                    v-model="productObj.page"
                    mode="single"
                    placeholder="Jak przygotować"
                    track-by="name"
                    label="name"
                    :close-on-select="true"
                    :search="true"
                    :options="pages"
                  >
                  </Multiselect>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label">Zastosowania</label>
                <div class="col-sm-10">
                  <Multiselect
                    v-model="productObj.appliances"
                    mode="tags"
                    placeholder="Zastosowania"
                    track-by="name"
                    label="name"
                    :close-on-select="false"
                    :search="true"
                    :options="appliances"
                  >
                  </Multiselect>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label">
                  Dostępność w sklepach
                </label>
                <div class="col-sm-10">
                  <Multiselect
                    v-model="productObj.available_in_countries"
                    mode="tags"
                    placeholder="Dostępność w sklepach"
                    track-by="name"
                    label="name"
                    :close-on-select="false"
                    :search="true"
                    :options="countries"
                  >
                  </Multiselect>
                  <div
                    v-if="errorsAdminData?.data?.available_in_countries"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.available_in_countries[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label">Parametry</label>
                <div class="col-sm-5">
                  <div class="listbox1" v-if="parameters.length">
                    <h4>Nie wybrane</h4>
                    <ejs-listbox
                      :dataSource="parameters"
                      :fields="fields"
                      scope="#listbox"
                      :toolbarSettings="toolbarSettings"
                    ></ejs-listbox>
                  </div>
                </div>
                <div class="col-sm-5">
                  <div class="listbox2">
                    <h4>Wybrane</h4>
                    <ejs-listbox
                      id="listbox"
                      :dataSource="selectedParameters"
                      :fields="fields"
                      scope="combined-list"
                    >
                    </ejs-listbox>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label">
                  Opcje przypisane do produktu
                </label>
                <div class="col-sm-5">
                  <div class="listbox1" v-if="pOptions.length">
                    <h4>Nie wybrane</h4>
                    <ejs-listbox
                      :dataSource="pOptions"
                      :fields="fields"
                      scope="#listbox1"
                      :toolbarSettings="toolbarSettings"
                    ></ejs-listbox>
                  </div>
                </div>
                <div class="col-sm-5">
                  <div class="listbox2">
                    <h4>Wybrane</h4>
                    <ejs-listbox
                      id="listbox1"
                      :dataSource="selectedOptions"
                      :fields="fields"
                      scope="combined-list1"
                    >
                    </ejs-listbox>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label">Termin realizacji</label>
                <div class="col-sm-10">
                  <input
                    v-model="productObj.deadline_time"
                    type="number"
                    class="form-control inputClass"
                    placeholder="Termin realizacji"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.deadline_time"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.deadline_time[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label">Deadline</label>
                <div class="col-sm-10">
                  <input
                    v-model="productObj.deadline"
                    type="number"
                    class="form-control inputClass"
                    placeholder="Deadline"
                    required="required"
                  />
                  <div v-if="errorsAdminData?.data?.deadline" class="has-error">
                    <span class="help-block">
                      {{ errorsAdminData.data.deadline[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label">
                  Grupa opcji określająca termin realizacji
                </label>
                <div class="col-sm-10">
                  <Multiselect
                    v-model="productObj.option_group_for_deadline"
                    mode="single"
                    placeholder="Grupa opcji określająca termin realizacji"
                    track-by="name"
                    label="name"
                    :close-on-select="true"
                    :search="true"
                    :options="groupOptionsDeadline"
                  >
                  </Multiselect>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label">Waga jednostkowa</label>
                <div class="col-sm-10">
                  <input
                    v-model="productObj.unit_weight"
                    type="number"
                    class="form-control inputClass"
                    placeholder="Waga jednostkowa"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.unit_weight"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.unit_weight[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="col-sm-offset-2 col-sm-10">
                  <div class="checkbox checkbox-primary">
                    <label>
                      <input
                        v-model="productObj.horizontal"
                        name="horizontal"
                        type="checkbox"
                      />
                      <span>Orientacja pozioma</span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="col-sm-offset-2 col-sm-10">
                  <div class="checkbox checkbox-primary">
                    <label>
                      <input
                        v-model="productObj.is_published"
                        name="is_published"
                        type="checkbox"
                      />
                      <span>Opublikowany</span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="col-sm-offset-2 col-sm-10">
                  <div class="checkbox checkbox-primary">
                    <label>
                      <input
                        v-model="productObj.is_popular"
                        name="is_popular"
                        type="checkbox"
                      />
                      <span>Wyświetlaj w najpopularniejszych produktach</span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="col-sm-offset-2 col-sm-10">
                  <div class="checkbox checkbox-primary">
                    <label>
                      <input
                        v-model="productObj.frozen_price"
                        name="frozen_price"
                        type="checkbox"
                      />
                      <span>Zamrożona cena</span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="col-sm-offset-2 col-sm-10">
                  <div class="checkbox checkbox-primary">
                    <label>
                      <input
                        v-model="productObj.imposition"
                        name="imposition"
                        type="checkbox"
                      />
                      <span>Do impozycji</span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="col-sm-offset-2 col-sm-10">
                  <div class="checkbox checkbox-primary">
                    <label>
                      <input
                        v-model="productObj.exclusive_sheet"
                        name="exclusive_sheet"
                        type="checkbox"
                      />
                      <span>Wyłączność na arkuszu</span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-2">Obrazek</label>
                <div class="col-sm-3">
                  <picture-input
                    ref="pictureInput"
                    width="300"
                    height="300"
                    margin="16"
                    accept="image/jpeg,image/png"
                    size="10"
                    button-class="btn"
                    :custom-strings="{
                      upload: '<h1>Uploading!</h1>',
                      drag: 'Drag & Drop',
                    }"
                    @change="changePhoto"
                  >
                  </picture-input>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label">Data od</label>
                <div class="col-sm-10">
                  <input
                    v-model="productObj.date_from"
                    type="text"
                    class="form-control inputClass"
                    placeholder="Data od"
                    required="required"
                  />
                  <div
                    v-if="errorsAdminData?.data?.date_from"
                    class="has-error"
                  >
                    <span class="help-block">
                      {{ errorsAdminData.data.date_from[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label">Data do</label>
                <div class="col-sm-10">
                  <input
                    v-model="productObj.date_to"
                    type="text"
                    class="form-control inputClass"
                    placeholder="Data do"
                    required="required"
                  />
                  <div v-if="errorsAdminData?.data?.date_to" class="has-error">
                    <span class="help-block">
                      {{ errorsAdminData.data.date_to[0] }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="col-sm-4 col-sm-offset-2">
                  <router-link class="btn btn-white" to="/products">
                    Anuluj
                  </router-link>
                  <button @click="create" class="btn btn-primary">Dodaj</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="mGroups" class="modal-dialog">
    <div class="modal-content">
      <div class="inmodal">
        <div class="modal-header">
          <h4 class="modal-title">Dodaj grupy opcji</h4>
        </div>
        <div class="modal-body">
          <div class="form-horizontal">
            <div class="ibox-content">
              <div class="form-group">
                <div class="col-sm-10">
                  <Multiselect
                    v-model="gOptions"
                    mode="tags"
                    placeholder="Grupy opcji"
                    track-by="name"
                    label="name"
                    :close-on-select="false"
                    :search="true"
                    :options="gOptionsExc"
                    :hideSelected="true"
                  >
                  </Multiselect>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button @click="mGroups = false" class="btn btn-white">Anuluj</button>
          <button @click="addGroup()" class="btn btn-primary">Dodaj</button>
        </div>
      </div>
    </div>
  </div>
  <div v-if="mGroups" @click="mGroups = false" class="modal-backdrop"></div>
  <div v-if="mOptions" class="modal-dialog">
    <div class="modal-content">
      <div class="inmodal">
        <div class="modal-header">
          <h4 class="modal-title">Dodaj opcje</h4>
        </div>
        <div class="modal-body">
          <div class="form-horizontal">
            <div class="ibox-content">
              <div class="form-group">
                <div class="col-sm-10">
                  <Multiselect
                    v-model="optionsG"
                    mode="tags"
                    placeholder="Opcje"
                    track-by="name"
                    label="name"
                    :close-on-select="false"
                    :search="true"
                    :options="optionsExc"
                    :hideSelected="true"
                  >
                  </Multiselect>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button @click="mOptions = false" class="btn btn-white">
            Anuluj
          </button>
          <button @click="addOption()" class="btn btn-primary">Dodaj</button>
        </div>
      </div>
    </div>
  </div>
  <div v-if="mOptions" @click="mOptions = false" class="modal-backdrop"></div>
  <div v-if="mQuantityPrices" class="modal-dialog">
    <div class="modal-content">
      <div class="inmodal">
        <div class="modal-header">
          <h4 class="modal-title">Ceny opcji w nakładach</h4>
        </div>
        <div class="modal-body">
          <div class="form-horizontal">
            <div class="ibox-content">
              <div class="form-group">
                <div class="col-sm-12">
                  <table
                    width="100%"
                    v-if="productObj.quantities[quantityIndex].prices"
                  >
                    <thead>
                      <th>ID</th>
                      <th>Opcja</th>
                      <th>Cena</th>
                    </thead>
                    <tbody
                      v-for="(price, key) in productObj.quantities[
                        quantityIndex
                      ].prices"
                      :key="key"
                    >
                      <td>{{ key }}</td>
                      <td>Opcja {{ key }}</td>
                      <td>
                        <input
                          v-model="
                            productObj.quantities[quantityIndex].prices[key]
                          "
                          type="number"
                          class="form-control inputClass"
                          placeholder="Cena"
                          required="required"
                        />
                      </td>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button @click="mQuantityPrices = false" class="btn btn-primary">
            Zamknij
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    v-if="mQuantityPrices"
    @click="mQuantityPrices = false"
    class="modal-backdrop"
  />
  <div v-if="mGroupPrices" class="modal-dialog">
    <div class="modal-content">
      <div class="inmodal">
        <div class="modal-header">
          <h4 class="modal-title">Ceny w zależności od nakładu</h4>
        </div>
        <div class="modal-body">
          <div class="form-horizontal">
            <div class="ibox-content">
              <div class="form-group">
                <div class="col-sm-12">
                  <EasyDataTable
                    v-if="productObj.quantities"
                    :headers="quantityHeaders"
                    :items="productObj.quantities"
                    :loading="loading"
                    :clickRowToExpand="true"
                    alternating
                    show-index
                  >
                    <template #item-price="item">
                      <div v-if="item.isEdit">
                        <input
                          v-model="item.prices[groupPricesPk]"
                          type="number"
                          class="form-control inputClass"
                          placeholder="Cena"
                          required="required"
                        />
                      </div>
                      <div v-else>
                        {{ item.prices[groupPricesPk] }}
                      </div>
                    </template>
                    <template #item-actions="item">
                      <button
                        v-if="!item.isEdit"
                        @click="editOption(item)"
                        class="btn btn-warning btn-xs"
                      >
                        <i class="fa fa-pencil" aria-hidden="true"></i>
                      </button>
                      <button
                        v-if="item.isEdit"
                        @click="saveOptionPrice(item)"
                        class="btn btn-success btn-xs"
                      >
                        <i class="fa fa-check" aria-hidden="true"></i>
                      </button>
                      <button
                        v-if="item.isEdit"
                        @click="cancelEdit(item)"
                        class="btn btn-danger btn-xs"
                      >
                        <i class="fa fa-trash"></i>
                      </button>
                    </template>
                  </EasyDataTable>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button @click="mGroupPrices = false" class="btn btn-primary">
            Zamknij
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    v-if="mGroupPrices"
    @click="mGroupPrices = false"
    class="modal-backdrop"
  />
</template>

<script>
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import router from "../../router/router";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import PictureInput from "vue-picture-input";
import { ListBoxComponent as EjsListbox } from "@syncfusion/ej2-vue-dropdowns";
import "/node_modules/@syncfusion/ej2-base/styles/material.css";
import "/node_modules/@syncfusion/ej2-inputs/styles/material.css";
import "/node_modules/@syncfusion/ej2-dropdowns/styles/material.css";

export default {
  name: "ProductsCreate",
  mixins: [],
  components: {
    Multiselect,
    PictureInput,
    EjsListbox,
  },
  props: {},

  setup() {
    const productObj = ref({
      name: "",
      model: "",
      type: "",
      image: "",
      quantities: [],
      options: [],
      group_options: [],
    });

    const countries = ref(["PL", "DE"]);
    const materials = ref([]);
    const quantities = ref({});
    const gOptions = ref([]);
    const gOptionsExc = ref([]);
    const optionsExc = ref([]);
    const options = ref([]);
    const optionsGAll = ref([]);
    const optionsG = ref([]);
    const formats = ref([]);
    const packages = ref([]);
    const categories = ref([]);
    const pages = ref([]);
    const appliances = ref([]);
    const parameters = ref([]);
    const paOptions = ref([]);
    const pOptions = ref([]);
    const selectedOptions = ref([]);
    const selectedParameters = ref([]);
    const errorsAdminData = ref();
    const groupIndex = ref();
    const quantityIndex = ref();
    const mGroups = ref(false);
    const mGroupPrices = ref(false);
    const groupPricesPk = ref();
    const mQuantityPrices = ref(false);
    const mOptions = ref(false);
    const isEdit = ref(false);
    const showErrors = ref(false);
    const showModalErrors = ref(false);

    const store = useStore();
    const createProductsData = computed(
      () => store.getters.getCreateProductsData
    );
    const productMaterialsData = computed(
      () => store.getters.getProductMaterialsData
    );
    const productOptionsData = computed(
      () => store.getters.getProductOptionsData
    );
    const productFormatsData = computed(
      () => store.getters.getProductFormatsData
    );
    const categoriesData = computed(() => store.getters.getCategoriesData);
    const packagesData = computed(() => store.getters.getPackagesData);
    const pagesData = computed(() => store.getters.getPagesData);
    const appliancesData = computed(() => store.getters.getAppliancesData);
    const parametersData = computed(() => store.getters.getAdminParametersData);
    const paOptionsGroupData = computed(
      () => store.getters.getpaOptionsGroupData
    );
    const adminProductOptionsData = computed(
      () => store.getters.getAdminProductOptionsData
    );
    const pogData = computed(() => store.getters.getPOGData);
    const fields = { text: "Name" };
    const toolbarSettings = {
      items: [
        "moveUp",
        "moveDown",
        "moveTo",
        "moveFrom",
        "moveAllTo",
        "moveAllFrom",
      ],
    };
    let headers = [
      { text: "Nakład", value: "quantity", sortable: true },
      {
        text: "Nakład widoczny",
        value: "display_quantity",
        sortable: true,
      },
      {
        text: "Cena",
        value: "price",
        sortable: true,
      },
      {
        text: "Dni realizacji",
        value: "realization_days",
        sortable: true,
      },
      { text: "Akcje", value: "actions" },
    ];
    let quantityHeaders = [
      { text: "Nakład", value: "quantity", sortable: true },
      {
        text: "Cena",
        value: "price",
        sortable: true,
      },
      { text: "Akcje", value: "actions" },
    ];
    let quantityPriceHeaders = [
      { text: "ID", value: "pk", sortable: true },
      {
        text: "Opcja",
        value: "option",
        sortable: true,
      },
      {
        text: "Cena",
        value: "price",
        sortable: true,
      },
      { text: "Akcje", value: "actions" },
    ];
    let headersGroup = [
      { text: "ID", value: "pk", sortable: true },
      {
        text: "Nazwa",
        value: "name",
        sortable: true,
      },
      { text: "Akcje", value: "actions" },
    ];

    const create = () => {
      showErrors.value = true;
      store.dispatch("createProducts", productObj.value);
    };

    const addGroup = () => {
      gOptions.value[0].options = [];
      productObj.value.group_options.push(...gOptions.value);
      mGroups.value = false;
      addGroupRef();
    };

    const addOption = () => {
      if (!productObj.value.group_options[groupIndex.value].options) {
        productObj.value.group_options[groupIndex.value].options = [];
      }
      productObj.value.group_options[groupIndex.value].options.push(
        ...optionsG.value
      );
      mOptions.value = false;

      for (let index = 0; index < productObj.value.quantities.length; index++) {
        if (!productObj.value.quantities[index].prices) {
          productObj.value.quantities[index].prices = [];
        }
        for (let i = 0; i < optionsG.value.length; i++) {
          productObj.value.quantities[index].prices = {
            ...productObj.value.quantities[index].prices,
            ...{ [optionsG.value[i].pk]: 0 },
          };
        }
      }
      addOptionRef();
    };

    const addGroupRef = () => {
      gOptionsExc.value = paOptions.value.filter(function (el) {
        return productObj.value.group_options.indexOf(el.value) < 0;
      });
      gOptions.value = [];
    };

    const addOptionRef = () => {
      optionsExc.value = optionsGAll.value.filter(function (el) {
        return productObj.value.options.indexOf(el.value) < 0;
      });
      optionsG.value = [];
    };

    const addQuantities = () => {
      let index = 0;
      if (productObj.value.quantities.length > 0) {
        productObj.value.quantities[
          productObj.value.quantities.length - 1
        ].isNew = false;

        index =
          productObj.value.quantities[productObj.value.quantities.length - 1]
            .id + 1;
        if (!index && index !== 0) {
          index = 0;
        }
      }

      productObj.value.quantities.push({
        quantity: "",
        display_quantity: "",
        price: "",
        realization_days: "",
        id: index,
        isNew: true,
      });
    };

    const saveQuantity = () => {
      showModalErrors.value = true;

      if (
        quantities.value.quantities !== "" &&
        quantities.value.display_quantity !== "" &&
        quantities.value.price !== ""
      ) {
        if (productObj.value.quantities.length > 0) {
          quantities.value.id =
            productObj.value.quantities[
              productObj.value.quantities.length - 1
            ].id;
          productObj.value.quantities[productObj.value.quantities.length - 1] =
            quantities.value;

          for (
            let index = 0;
            index < productObj.value.quantities.length;
            index++
          ) {
            if (!productObj.value.quantities[index].prices) {
              productObj.value.quantities[index].prices = [];
            }
            for (let i = 0; i < optionsG.value.length; i++) {
              productObj.value.quantities[index].prices = [
                {
                  ...productObj.value.quantities[index].prices,
                  ...{ [optionsG.value[i].pk]: 0 },
                },
              ];
            }
          }
        }
        quantities.value = {};
        showModalErrors.value = false;
      }
    };

    const removeQuantity = (item) => {
      console.log(item);
    };
    const removeGroup = (item) => {
      const index = productObj.value.group_options.findIndex(
        (obj) => obj.pk === item.pk
      );
      productObj.value.group_options = [
        ...productObj.value.group_options.slice(0, index),
        ...productObj.value.group_options.slice(index + 1),
      ];
      addGroupRef();
    };

    const addGroupOptions = () => {
      mGroups.value = true;
    };

    const getPOG = (item) => {
      store.dispatch("getPOG", { pk: item.pk });
    };

    const openOptionsModal = (item) => {
      let index = productObj.value.group_options.findIndex(
        (x) => x.pk == item.pk
      );
      mOptions.value = true;
      groupIndex.value = index;
    };

    const quantityPrices = (item) => {
      let index = productObj.value.quantities.findIndex((x) => x.id == item.id);
      mQuantityPrices.value = true;
      quantityIndex.value = index;
      if (!productObj.value.quantities[index].prices) {
        productObj.value.quantities[index].prices = [];
      }
    };

    const groupPrices = (item) => {
      groupPricesPk.value = item.pk;
      mGroupPrices.value = true;
    };

    const editOption = (item) => {
      let index = productObj.value.quantities.findIndex((x) => x.id == item.id);
      productObj.value.quantities[index].isEdit = true;
    };

    const editQuantityPrice = (item) => {
      console.log(item);
    };

    const cancelEdit = (item) => {
      let index = productObj.value.quantities.findIndex((x) => x.id == item.id);
      productObj.value.quantities[index].isEdit = false;
    };
    const saveOptionPrice = (item) => {
      let index = productObj.value.quantities.findIndex((x) => x.id == item.id);
      productObj.value.quantities[index].isEdit = false;
    };

    store.dispatch("getProductMaterials");
    store.dispatch("getProductOptions");
    store.dispatch("getProductFormats");
    store.dispatch("getPackages");
    store.dispatch("getCategories");
    store.dispatch("getPages");
    store.dispatch("getAdminParameters");
    store.dispatch("getAppliances");
    store.dispatch("getAdminProductOptions");
    store.dispatch("getpOptionsAdminGroup");

    watch(
      () => createProductsData.value,
      function (newValue) {
        if (newValue.status === 201) {
          router.push({ name: "productsList", path: "/products" }).then(() => {
            store.dispatch("showToaster", {
              type: "success",
              content: "Nowy produkt został dodany",
            });
          });
        } else {
          var msg = [];
          for (const [key, value] of Object.entries(newValue.data)) {
            msg.push(key + ": " + value);
          }
          store.dispatch("showToaster", {
            type: "error",
            content: msg[0],
          });
          errorsAdminData.value = newValue;
        }
      }
    );

    watch(
      () => productMaterialsData.value,
      function (newValue) {
        if (newValue.status === 200) {
          newValue.data?.results.forEach((element) => {
            materials.value.push({
              name: "[" + element.pk + "] " + element.name,
              value: element.pk,
            });
          });
        }
      }
    );

    watch(
      () => productOptionsData.value,
      function (newValue) {
        if (newValue.status === 200) {
          newValue.data?.forEach((element) => {
            options.value.push({
              name: element[1],
              value: element[0],
            });
          });
        }
      }
    );

    watch(
      () => productFormatsData.value,
      function (newValue) {
        if (newValue.status === 200) {
          newValue.data?.forEach((element) => {
            formats.value.push({
              name:
                "[" +
                element.pk +
                "] " +
                element.size_x +
                " x " +
                element.size_y,
              value: element.pk,
            });
          });
        }
      }
    );
    watch(
      () => packagesData.value,
      function (newValue) {
        if (newValue.status === 200) {
          newValue.data?.results.forEach((element) => {
            packages.value.push({
              name: "[" + element.pk + "] " + element.name,
              value: element.pk,
            });
          });
        }
      }
    );
    watch(
      () => categoriesData.value,
      function (newValue) {
        if (newValue.status === 200) {
          newValue.data?.results.forEach((element) => {
            categories.value.push({
              name: "[" + element.pk + "] " + element.title,
              value: element.pk,
            });
          });
        }
      }
    );
    watch(
      () => pagesData.value,
      function (newValue) {
        if (newValue.status === 200) {
          newValue.data?.results.forEach((element) => {
            pages.value.push({
              name: "[" + element.pk + "] " + element.title_seo,
              value: element.pk,
            });
          });
        }
      }
    );
    watch(
      () => appliancesData.value,
      function (newValue) {
        if (newValue.status === 200) {
          newValue.data?.results.forEach((element) => {
            appliances.value.push({
              name: "[" + element.pk + "] " + element.name,
              value: element.pk,
            });
          });
        }
      }
    );
    watch(
      () => parametersData.value,
      function (newValue) {
        if (newValue.status === 200) {
          newValue.data?.forEach((element) => {
            parameters.value.push({
              Name: "[" + element.pk + "] " + element.name,
              Code: element.var_name,
            });
          });
        }
      }
    );

    watch(
      () => paOptionsGroupData.value,
      function (newValue) {
        if (newValue.status === 200) {
          paOptions.value = [];
          newValue.data?.forEach((element) => {
            paOptions.value.push({
              name: "[" + element.pk + "] " + element.name,
              value: { pk: element.pk, name: element.name },
            });
          });
          gOptionsExc.value = paOptions.value;
        }
      }
    );

    watch(
      () => adminProductOptionsData.value,
      function (newValue) {
        if (newValue.status === 200) {
          newValue.data?.forEach((element) => {
            pOptions.value.push({
              Name:
                "[" +
                element.options_group +
                "] " +
                element.options_group_name +
                "[" +
                element.pk +
                "]" +
                element.label,
              Code: element.var_name,
            });
          });
        }
      }
    );

    watch(
      () => pogData.value,
      function (newValue) {
        if (newValue.status === 200) {
          optionsGAll.value = [];
          newValue.data.product_options?.forEach((element) => {
            optionsGAll.value.push({
              name: "[" + element.pk + "] " + element.label,
              value: {
                pk: element.pk,
                name: element.label,
              },
            });
          });
          optionsExc.value = optionsGAll.value;
        }
      }
    );

    return {
      productObj,
      errorsAdminData,
      showErrors,
      create,
      mGroups,
      mOptions,
      addGroup,
      headers,
      showModalErrors,
      isEdit,
      addQuantities,
      saveQuantity,
      removeQuantity,
      materials,
      options,
      optionsG,
      formats,
      packages,
      categories,
      pages,
      appliances,
      countries,
      selectedParameters,
      parameters,
      fields,
      toolbarSettings,
      pOptions,
      selectedOptions,
      quantities,
      addGroupOptions,
      paOptions,
      headersGroup,
      quantityPriceHeaders,
      gOptions,
      gOptionsExc,
      optionsExc,
      removeGroup,
      getPOG,
      addOption,
      openOptionsModal,
      quantityPrices,
      mQuantityPrices,
      quantityIndex,
      mGroupPrices,
      groupPrices,
      quantityHeaders,
      groupPricesPk,
      editOption,
      cancelEdit,
      saveOptionPrice,
      editQuantityPrice,
    };
  },
  computed: {},
};
</script>
<style>
@import "../../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
</style>
