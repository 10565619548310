import { createRouter, createWebHistory } from "vue-router";
import { useCookies } from "vue3-cookies";
import Home from "../components/caffeprint/Home.vue";
import Login from "../components/user/Login.vue";
import AdminsList from "../components/user/AdminsList.vue";
import AdminsCreate from "../components/user/AdminsCreate.vue";
import AdminBarcode from "../components/user/AdminBarcode.vue";
import GroupsList from "../components/group/GroupsList.vue";
import GroupsCreate from "../components/group/GroupsCreate.vue";
import CompetenciesList from "../components/competence/CompetenciesList.vue";
import CompetenciesCreate from "../components/competence/CompetenciesCreate.vue";
import IpsList from "../components/ip/IpsList.vue";
import IpsCreate from "../components/ip/IpsCreate.vue";
import SettingsList from "../components/setting/SettingsList.vue";
import HolidaysList from "../components/holidays/HolidaysList.vue";
import SettingsCreate from "../components/setting/SettingsCreate.vue";
import PackagesList from "../components/package/PackagesList.vue";
import PackagesCreate from "../components/package/PackagesCreate.vue";
import MachinesList from "../components/machines/MachinesList.vue";
import MachinesEdit from "../components/machines/MachinesEdit.vue";
import MachinesCreate from "../components/machines/MachinesCreate.vue";
import ProcessesList from "../components/processes/ProcessesList.vue";
import ProcessesCreate from "../components/processes/ProcessesCreate.vue";
import PlatesList from "../components/plates/PlatesList.vue";
import PlatesCreate from "../components/plates/PlatesCreate.vue";
import VariablesList from "../components/variables/VariablesList.vue";
import SystemlogsList from "../components/logs/SystemlogsList.vue";
import ProductionlogsList from "../components/logs/ProductionlogsList.vue";
import NewsList from "../components/news/NewsList.vue";
import NewsCreate from "../components/news/NewsCreate.vue";
import BannersList from "../components/banner/BannersList.vue";
import BannersCreate from "../components/banner/BannersCreate.vue";
import PagesList from "../components/pages/PagesList.vue";
import PagesCreate from "../components/pages/PagesCreate.vue";
import FaqCreate from "../components/faq/FaqCreate.vue";
import FaqList from "../components/faq/FaqList.vue";
import TranslationsList from "../components/translations/TranslationsList.vue";
import TranslationsModelsList from "../components/translations/TranslationsModelsList.vue";
import FilesList from "../components/file/FilesList.vue";
import FilesCreate from "../components/file/FilesCreate.vue";
import CategoriesList from "../components/categories/CategoriesList.vue";
import CategoriesCreate from "../components/categories/CategoriesCreate.vue";
import ProductsList from "../components/product/ProductsList.vue";
import ProductsCreate from "../components/product/ProductsCreate.vue";
import ProductsEdit from "../components/product/ProductsEdit.vue";
import ParametersList from "../components/parameters/ParametersList.vue";
import ParametersCreate from "../components/parameters/ParametersCreate.vue";
import FormatGroupCreate from "../components/format-group/FormatGroupCreate.vue";
import FormatGroupList from "../components/format-group/FormatGroupList.vue";
import FormatGroupEdit from "../components/format-group/FormatGroupEdit.vue";
import FormatList from "../components/format/FormatList.vue";
import FormatCreate from "../components/format/FormatCreate.vue";
import ProductOptionCreate from "../components/product-option-group/ProductOptionCreate.vue";
import ProductOptionList from "../components/product-option-group/ProductOptionList.vue";
import ProductOptionExCreate from "../components/product-option-exclusion/ProductOptionExCreate.vue";
import ProductOptionExList from "../components/product-option-exclusion/ProductOptionExList.vue";
import AppliancesList from "../components/appliances/AppliancesList.vue";
import AppliancesCreate from "../components/appliances/AppliancesCreate.vue";
import MontagesList from "../components/montages/MontagesList.vue";
import MontagesCreate from "../components/montages/MontagesCreate.vue";
import MontagesEdit from "../components/montages/MontagesEdit.vue";
import MontagesArchList from "../components/montages/MontagesArchList.vue";
import OrderSend from "../components/order/Send.vue";
import OrderCreate from "../components/order/OrderCreate.vue";
import OrderList from "../components/order/OrderList.vue";
import OrderPacking from "../components/order/Packing.vue";
import CubesList from "../components/cubes/CubesList.vue";
import CubeDetails from "../components/cubes/CubeDetails.vue";
import ClientsList from "../components/clients/ClientsList.vue";
import ClientsCreate from "../components/clients/ClientsCreate.vue";
import DiscountGroupsList from "../components/discount-groups/DiscountGroupsList.vue";
import DiscountGroupsCreate from "../components/discount-groups/DiscountGroupsCreate.vue";
import MessagesList from "../components/messages/MessagesList.vue";
import MessagesDetails from "../components/messages/MessagesDetails.vue";
import MessagesCreate from "../components/messages/MessagesCreate.vue";
import DiscountList from "../components/discount/DiscountList.vue";
import InvoicesList from "../components/invoices/InvoicesList.vue";
import LegacyInvoicesList from "../components/invoices/LegacyInvoicesList.vue";
import PaymentImport from "../components/payment/PaymentImport.vue";

const { cookies } = useCookies();
const routerHistory = createWebHistory();
const router = createRouter({
  history: routerHistory,
  routes: [
    {
      path: "/",
      component: Home,
      name: "Home",
    },
    {
      path: "/login",
      name: "Login",
      component: Login,
    },
    {
      path: "/admins/:pk?",
      name: "Admins",
      component: AdminsList,
    },
    {
      path: "/admins/create",
      name: "AdminsCreate",
      component: AdminsCreate,
    },
    {
      path: "/admins/update",
      name: "AdminsUpdate",
      component: AdminsCreate,
    },
    {
      path: "/admins/:id/barcode",
      name: "AdminBarcode",
      component: AdminBarcode,
    },
    {
      path: "/groups",
      name: "Groups",
      component: GroupsList,
    },
    {
      path: "/groups/create",
      name: "GroupsCreate",
      component: GroupsCreate,
    },
    {
      path: "/competencies",
      name: "Competencies",
      component: CompetenciesList,
    },
    {
      path: "/competencies/create",
      name: "CompetenciesCreate",
      component: CompetenciesCreate,
    },
    {
      path: "/ips",
      name: "Ips",
      component: IpsList,
    },
    {
      path: "/ips/create",
      name: "IpsCreate",
      component: IpsCreate,
    },
    {
      path: "/settings",
      name: "Settings",
      component: SettingsList,
    },
    {
      path: "/settings/create",
      name: "SettingsCreate",
      component: SettingsCreate,
    },
    {
      path: "/holidays",
      name: "HolidaysList",
      component: HolidaysList,
    },
    {
      path: "/packages",
      name: "PackagesList",
      component: PackagesList,
    },
    {
      path: "/packages/create",
      name: "PackagesCreate",
      component: PackagesCreate,
    },
    {
      path: "/machines",
      name: "MachinesList",
      component: MachinesList,
    },
    {
      path: "/machines/create",
      name: "MachinesCreate",
      component: MachinesCreate,
    },
    {
      path: "/machines/edit/:pk",
      name: "MachinesEdit",
      component: MachinesEdit,
    },
    {
      path: "/processes",
      name: "ProcessesList",
      component: ProcessesList,
    },
    {
      path: "/processes/create",
      name: "ProcessesCreate",
      component: ProcessesCreate,
    },
    {
      path: "/processes",
      name: "ProcessesList",
      component: ProcessesList,
    },
    {
      path: "/processes/create",
      name: "ProcessesCreate",
      component: ProcessesCreate,
    },
    {
      path: "/plates",
      name: "PlatesList",
      component: PlatesList,
    },
    {
      path: "/plates/create",
      name: "PlatesCreate",
      component: PlatesCreate,
    },
    {
      path: "/variables",
      name: "VariablesList",
      component: VariablesList,
    },
    {
      path: "/systemlogs",
      name: "SystemlogsList",
      component: SystemlogsList,
    },
    {
      path: "/productionlogs",
      name: "ProductionlogsList",
      component: ProductionlogsList,
    },
    {
      path: "/news/list",
      name: "NewsList",
      component: NewsList,
    },
    {
      path: "/news/create",
      name: "NewsCreate",
      component: NewsCreate,
    },
    {
      path: "/banners/list",
      name: "BannersList",
      component: BannersList,
    },
    {
      path: "/banners/create",
      name: "BannersCreate",
      component: BannersCreate,
    },
    {
      path: "/pages",
      name: "PagesList",
      component: PagesList,
    },
    {
      path: "/pages/create",
      name: "PagesCreate",
      component: PagesCreate,
    },
    {
      path: "/faq/create",
      name: "FaqCreate",
      component: FaqCreate,
    },
    {
      path: "/faq",
      name: "FaqList",
      component: FaqList,
    },
    {
      path: "/translations",
      name: "TranslationsList",
      component: TranslationsList,
    },
    {
      path: "/translations/models-list",
      name: "TranslationsModelsList",
      component: TranslationsModelsList,
    },
    {
      path: "/file/list",
      name: "FilesList",
      component: FilesList,
    },
    {
      path: "/file/create",
      name: "FilesCreate",
      component: FilesCreate,
    },
    {
      path: "/categories/list",
      name: "CategoriesList",
      component: CategoriesList,
    },
    {
      path: "/categories/create",
      name: "CategoriesCreate",
      component: CategoriesCreate,
    },
    {
      path: "/products/create",
      name: "ProductsCreate",
      component: ProductsCreate,
    },
    {
      path: "/products",
      name: "ProductsList",
      component: ProductsList,
    },
    {
      path: "/products/edit/:pk",
      name: "ProductsEdit",
      component: ProductsEdit,
    },
    {
      path: "/parameters/create",
      name: "ParametersCreate",
      component: ParametersCreate,
    },
    {
      path: "/parameters",
      name: "ParametersList",
      component: ParametersList,
    },
    {
      path: "/format-groups",
      name: "FormatGroupsList",
      component: FormatGroupList,
    },
    {
      path: "/format-groups/create",
      name: "FormatGroupCreate",
      component: FormatGroupCreate,
    },
    {
      path: "/format-groups/edit/:pk",
      name: "FormatGroupEdit",
      component: FormatGroupEdit,
    },
    {
      path: "/format/list",
      name: "FormatsList",
      component: FormatList,
    },
    {
      path: "/format/create",
      name: "FormatCreate",
      component: FormatCreate,
    },
    {
      path: "/product-option-group/list",
      name: "ProductOptionList",
      component: ProductOptionList,
    },
    {
      path: "/product-option-group/create",
      name: "ProductOptionCreate",
      component: ProductOptionCreate,
    },
    {
      path: "/product-option-exclusion/list",
      name: "ProductOptionExList",
      component: ProductOptionExList,
    },
    {
      path: "/product-option-exclusion/create",
      name: "ProductOptionExCreate",
      component: ProductOptionExCreate,
    },
    {
      path: "/appliances",
      name: "AppliancesList",
      component: AppliancesList,
    },
    {
      path: "/appliances/create",
      name: "AppliancesCreate",
      component: AppliancesCreate,
    },
    {
      path: "/montages/list",
      name: "MontagesList",
      component: MontagesList,
    },
    {
      path: "/montages/create",
      name: "MontagesCreate",
      component: MontagesCreate,
    },
    {
      path: "/montages/edit/:pk",
      name: "MontagesEdit",
      component: MontagesEdit,
    },
    {
      path: "/montages/list-archive",
      name: "MontagesArchList",
      component: MontagesArchList,
    },
    {
      path: "/order/send",
      name: "OrderSend",
      component: OrderSend,
    },
    {
      path: "/order/create",
      name: "OrderCreate",
      component: OrderCreate,
    },
    {
      path: "/order/list",
      name: "OrderList",
      component: OrderList,
    },
    {
      path: "/order/send/:pk",
      name: "OrderPacking",
      component: OrderPacking,
    },
    {
      path: "/cubes/list",
      name: "CubesList",
      component: CubesList,
    },
    {
      path: "/cubes/details/:pk",
      name: "CubeDetails",
      component: CubeDetails,
    },
    {
      path: "/clients/list",
      name: "ClientsList",
      component: ClientsList,
    },
    {
      path: "/clients/create",
      name: "ClientsCreate",
      component: ClientsCreate,
    },
    {
      path: "/discount-groups/list",
      name: "DiscountGroupsList",
      component: DiscountGroupsList,
    },
    {
      path: "/discount/list",
      name: "DiscountList",
      component: DiscountList,
    },
    {
      path: "/discount-groups/create",
      name: "DiscountGroupsCreate",
      component: DiscountGroupsCreate,
    },
    {
      path: "/messages/list",
      name: "MessagesList",
      component: MessagesList,
    },
    {
      path: "/messages/details/:pk",
      name: "MessagesDetails",
      component: MessagesDetails,
    },
    {
      path: "/messages/create",
      name: "MessagesCreate",
      component: MessagesCreate,
    },
    {
      path: "/invoices/list",
      name: "InvoicesList",
      component: InvoicesList,
    },
    {
      path: "/invoices/legacy_list",
      name: "LegacyInvoicesList",
      component: LegacyInvoicesList,
    },
    {
      path: "/payment/import",
      name: "PaymentImport",
      component: PaymentImport,
    },
  ],
  scrollBehavior() {
    return { top: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  var isLogged = cookies.get("CPsession");
  if (!isLogged && to.name !== "Login") {
    next("/login");
  } else {
    next();
  }
});

export default router;
